import React, { useContext } from "react";
import { fullTimeCompanies } from "../constants";
import { ThemeContext } from "../themeProvider";
import { motion } from "framer-motion";

const FullTimeCompanies = () => {
  const theme = useContext(ThemeContext);

  return (
    <div id="companies">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 px-4 pt-24 pb-12">
        <div style={{ width: "100%", alignItems: "center", justifyContent: "center", alignContent: "center", display: "flex" }}>
          <h2
            className={
              theme.state.darkMode
                ? "text-5xl font-bold px-4 md:px-0 text-center colored-bottom-line"
                : "text-5xl font-bold px-4 md:px-0 text-center text-white colored-bottom-line"
            }
          >
            Companies
          </h2>
        </div>
        
        <h4 className="mt-16 text-3xl font-semibold multicolortext" style={{ width: "max-content" }}>
          Where I've Worked At
        </h4>

        <div className="mt-8 space-y-6">
          {fullTimeCompanies.map((company) => (
            <motion.div
              key={company.name}
              initial="hidden"
              whileInView={"visible"}
              variants={{
                visible: {
                  opacity: 1,
                  scale: 1,
                  transition: {
                    type: "spring",
                  },
                },
                hidden: { opacity: 0, scale: 0.8 },
              }}
              className={
                theme.state.darkMode
                  ? "bg-white rounded-lg p-6 shadow-lg"
                  : "bg-gray-800 rounded-lg p-6 shadow-lg"
              }
            >
              <div className="flex flex-col md:flex-row justify-between md:items-center">
                <h3 className={
                  theme.state.darkMode
                    ? "text-2xl font-bold text-black"
                    : "text-2xl font-bold text-white"
                }>{company.name}</h3>
                <span className="text-blue-500 font-semibold">{company.period}</span>
              </div>
              <h4 className="text-xl mt-2 font-semibold multicolortext">{company.role}</h4>
              <p className={
                theme.state.darkMode
                  ? "mt-4 text-gray-600"
                  : "mt-4 text-gray-300"
              }>{company.description}</p>
            </motion.div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default FullTimeCompanies;
