import React, { useContext, useMemo } from "react";
import { projectsData } from "../constants";
import { ThemeContext } from "../themeProvider";
import { motion } from "framer-motion";

const Companies = () => {
  const theme = useContext(ThemeContext);

  // Get unique companies and their projects
  const companyProjects = useMemo(() => {
    const companies = {};
    projectsData.forEach(project => {
      if (project.company) {
        if (!companies[project.company]) {
          companies[project.company] = [];
        }
        companies[project.company].push(project);
      }
    });
    return companies;
  }, []);

  return (
    <div>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 px-4 pb-12">
        {/* <div style={{ width: "100%", alignItems: "center", justifyContent: "center", alignContent: "center", display: "flex" }}>
          <h2
            className={
              theme.state.darkMode
                ? "text-5xl font-bold px-4 md:px-0 text-center colored-bottom-line"
                : "text-5xl font-bold px-4 md:px-0 text-center text-white colored-bottom-line"
            }
          >
            Freelance Projects
          </h2>
        </div> */}
        
        <h4 className="mt-16 text-3xl font-semibold multicolortext" style={{ width: "max-content" }}>
          Who I've Worked With
        </h4>

        <div className="mt-8 grid grid-cols-1 md:grid-cols-2 gap-8">
          {Object.entries(companyProjects).map(([company, projects]) => (
            <motion.div
              key={company}
              initial="hidden"
              whileInView={"visible"}
              variants={{
                visible: {
                  opacity: 1,
                  scale: 1,
                  transition: {
                    type: "spring",
                  },
                },
                hidden: { opacity: 0, scale: 0.8 },
              }}
              className={
                theme.state.darkMode
                  ? "bg-white rounded-lg p-6 shadow-lg"
                  : "bg-gray-800 rounded-lg p-6 shadow-lg"
              }
            >
              <h3 className={
                theme.state.darkMode
                  ? "text-2xl font-bold mb-4 text-black"
                  : "text-2xl font-bold mb-4 text-white"
              }>{company}</h3>
              <div className="space-y-2">
                {projects.map(project => (
                  <div key={project.name} className={
                    theme.state.darkMode
                      ? "text-gray-600"
                      : "text-gray-300"
                  }>
                    • {project.name}
                  </div>
                ))}
              </div>
            </motion.div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Companies;
