import React, { useContext } from "react";
import { techStack } from "../constants";
import { ThemeContext } from "../themeProvider";
import { motion } from "framer-motion";

const About = () => {
  const theme = useContext(ThemeContext);
  const darkMode = theme.state.darkMode;
  return (
    <div id="about"
    // className={darkMode === true ? "bg-white" : "bg-gray-900"}
    >
      <div className="max-w-7xl mx-auto x-4 sm:px-6 lg:px-8 px-4 md:mt-0 pt-24 pb-12">
        <div style={{ width: "100%", alignItems: "center", justifyContent: "center", alignContent: "center", display: "flex" }}>
          <h2
            className={
              darkMode
                ? "text-5xl font-bold px-4 md:px-0 text-center colored-bottom-line"
                : "text-5xl font-bold px-4 md:px-0 text-center text-white colored-bottom-line"
            }
          >
            About Me
          </h2>
        </div>
        <div>
          <motion.div>
            <h4 className="mt-12 text-3xl font-semibold multicolortext" style={{width: "max-content"}}>
              A bit about me
            </h4>
            <p
              className={
                darkMode
                  ? "mt-4 text-xl text-justify text-gray-500"
                  : "mt-4 text-xl text-justify text-white"
              }
            >
              Hello! I'm Deniz, a passionate Software Developer based in Istanbul, specializing in Unity and XR development. Currently, I work at Monster Notebook, where I push the limits of software and hardware with C#, .NET, and JavaScript. <br /><br />
              I've developed numerous applications for expos and festivals, delivering custom solutions for various clients. My focus is on creating immersive and innovative experiences using the latest technologies. <br /><br />
              When I'm not coding, I enjoy rapping, camping, and engaging in creative pursuits like drawing and music production. Let's connect to collaborate on exciting projects or discuss the latest tech trends!
            </p>
          </motion.div>
          <motion.div
          >
            <h4 className="mt-12 text-3xl font-semibold multicolortext" style={{width: "max-content"}}>
              Technologies and Tools
            </h4>
            <p
              className={
                darkMode
                  ? "mt-4 text-xl text-justify text-gray-500"
                  : "mt-4 text-xl text-justify text-white"
              }
            >
              Using a combination of cutting-edge technologies and reliable
              open-source software I build user-focused, performant apps and
              softwares for smartphones, tablets, and desktops.
            </p>
          </motion.div>
          <motion.div className="flex flex-wrap mt-8 flex flex-wrap justify-between ">
            {techStack.map((el, index) => (
              <motion.div
                key={el.name}
                initial="hidden"
                whileInView={"visible"}
                variants={{
                  visible: {
                    y: 0,
                    opacity: 1,
                    transition: {
                      type: "spring",
                    },
                  },
                  hidden: { opacity: 1, y: 80 },
                }}
                className="py-2 px-4 bg-gray-50 md:m-4 mx-2 mt-6 rounded-lg flex items-center hover:scale-125 cursor-pointer md:w-48 w-40"
              >
                <img alt="" src={el.link} className="w-12" />
                <h4 className="text-md ml-4">{el.name}</h4>
              </motion.div>
            ))}
          </motion.div>
        </div>
      </div>
    </div>
  );
};

export default About;
