import React, { useContext } from "react";
import { contactLinks } from "../constants";
import { ThemeContext } from "../themeProvider";

const Contact = () => {
  const theme = useContext(ThemeContext);
  const darkMode = theme.state.darkMode;
  return (
    <div
      id="contact"
    // className={
    //   darkMode
    //     ? "bg-gray-100 pt-24"// md:h-screen"
    //     : "bg-black pt-24 text-white md:h-screen"
    // }
    >
      <div className="max-w-7xl mx-auto x-4 sm:px-6 lg:px-8 px-4 ">
        <div style={{ width: "100%", alignItems: "center", justifyContent: "center", alignContent: "center", display: "flex" }}>
          <h2
            className={
              theme.state.darkMode
                ? "text-5xl font-bold px-4 md:px-0 text-center colored-bottom-line"
                : "text-5xl font-bold px-4 md:px-0 text-center text-white colored-bottom-line"
            }
          >
            Contact
          </h2>
        </div>
        <div>
          <h4 className="mt-12 text-3xl font-semibold multicolortext" style={{ width: "max-content" }}>
            Connect with me
          </h4>
          <p className="text-gray-500 text-xl">
            If you want to know more about me or my work, or hire me for projects,<br />
            or if you would just like to say hey, send me a message. I'd love to hear from you.
          </p>
        </div>
        <div className="flex justify-between items-center md:items-stretch  flex-col md:flex-row pb-24">
          <div className="w-full md:pr-8" style={{ position: "relative" }}>
            <div className="" style={{ position: "absolute", width: "100%", height: "100%", alignContent: "center", alignItems: "center", justifyContent: "center", display: "flex", zIndex: 1 }}>
              {/* <div style={{ width: "max" }}></div> */}
              <a href="mailto:denizyunusgogus@gmail.com" className="multicolortext" style={{ boxShadow: "0 0 8px 8px black, 0 0 15px 15px #4c4704, 0 0 22px 22px #FCEE0C, 0 0 26px 26px #ff5bf8" }}>
                Send me email directly
              </a>
            </div>
            <div style={{ position: "absolute", width: "100%", height: "100%", backgroundColor: "black", opacity: 0.7, justifyContent: "center", alignContent: "center" }} />
            <form>
              <div className="my-6">
                <label
                  htmlFor="name"
                  className={
                    darkMode
                      ? "block mb-2 text-lg font-medium text-gray-900"
                      : "block mb-2 text-lg font-medium text-white"
                  }
                >
                  Name
                </label>
                <input
                  type="email"
                  id="name"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="Enter your name"
                  required
                />
              </div>
              <div className="mb-4">
                <label
                  htmlFor="email"
                  className={
                    darkMode
                      ? "block mb-2 text-lg font-medium text-gray-900"
                      : "block mb-2 text-lg font-medium text-white"
                  }
                >
                  Email
                </label>
                <input
                  type="email"
                  id="email"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="Enter your email"
                  required
                />
              </div>
              <div className="mb-4">
                <label
                  htmlFor="message"
                  className={
                    darkMode
                      ? "block mb-2 text-lg font-medium text-gray-900"
                      : "block mb-2 text-lg font-medium text-white"
                  }
                >
                  Message
                </label>
                <textarea
                  id="message"
                  className="bg-gray-50 border border-gray-300 text-gray-900 h-28 w-full text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="Enter your message"
                  required
                />
              </div>
              <div className="flex justify-between ">
                <div className="underline">
                  <a href="mailto:denizyunusgogus@gmail.com">
                    Send me email directly
                  </a>
                </div>
                <button className="bg-indigo-500 text-white px-4 py-2 w-40 rounded-md hover:bg-indigo-400">
                  <a href="mailto:denizyunusgogus@gmail.com">Submit</a>
                </button>
              </div>
            </form>
          </div>
          <div className="w-full flex flex-col md:items-end  mt-12 md:mt-6">
            {/* <h1 className="text-3xl font-bold">Phone</h1>
            <a
              href="hello"
              className="mb-12 mt-4 font-semibold text-blue-700 block uppercase"
            >
              +91 8285631499
            </a> */}
            <h1 className="text-3xl font-bold" style={{ color: "#FCEE0C" }}>Email</h1>
            <a
              href="mailto:denizyunusgogus@gmail.com"
              className="mb-12 mt-4 font-semibold text-blue-700 block uppercase"
              style={{ color: "#ff5bf8" }}
            >
              denizyunusgogus@gmail.com
            </a>
            <h1 className="text-3xl  font-bold" style={{ color: "#FCEE0C" }}>Address</h1>
            <a
              className="mt-4  mb-12 md:text-right font-semibold text-blue-700 block uppercase"
              style={{ color: "#ff5bf8" }}
            >
              Istanbul
              <br />
              Turkiye
            </a>
            <h1 className="text-3xl  font-bold" style={{ color: "#FCEE0C" }}>Social</h1>
            <ul className="flex">
              {contactLinks.map((el) => (
                <a
                  key={el.name}
                  href={el.link}
                  style={{ width: "40px" }}
                  className="md:ml-6 md:mr-0 mr-6 cursor-pointer mt-4 hover:scale-125 flex flex-col justify-center items-center"
                >
                  <img alt="" src={el.url} />
                  {/* <p className="text-md mt-2 hover:hidden">{el.name}</p> */}
                </a>
              ))}
            </ul>
          </div>
        </div>
      </div>
      <div
        className={
          darkMode
            ? "w-full bg-white text-black text-lg py-3 flex justify-center md:mt-20"
            : "w-full bg-gray-900 text-white text-lg py-3 flex justify-center md:mt-20"
        }
      >
        𝒟𝑒𝓃𝒾𝓏 𝒴𝓊𝓃𝓊𝓈 𝒢𝑜𝑔𝓊𝓈
        {/* <div className="text-blue-500 px-2 text-2xl">&#9790;</div> */}
      </div>
    </div>
  );
};

export default Contact;
