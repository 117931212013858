import React, { useState, useContext, useMemo } from "react";
import Card from "../components/Card";
import { ThemeContext } from "../themeProvider";
import { projectsData } from "../constants";

const Projects = () => {
  const theme = useContext(ThemeContext);
  // const darkMode = theme.state.darkMode;

  const [videoStates, setVideoStates] = useState(projectsData.map(() => false));
  const [selectedTags, setSelectedTags] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState('');

  // Get unique tags and companies
  const allTags = useMemo(() => {
    const tags = new Set();
    projectsData.forEach(project => {
      project.tags.forEach(tag => tags.add(tag));
    });
    return Array.from(tags).sort();
  }, []);

  const allCompanies = useMemo(() => {
    const companies = new Set();
    projectsData.forEach(project => {
      if (project.company) companies.add(project.company);
    });
    return Array.from(companies).sort();
  }, []);

  const handleTagClick = (tag) => {
    setSelectedTags(prev =>
      prev.includes(tag)
        ? prev.filter(t => t !== tag)
        : [...prev, tag]
    );
  };

  const filteredProjects = useMemo(() => {
    return projectsData.filter(project => {
      const matchesTags = selectedTags.length === 0 ||
        selectedTags.every(tag => project.tags.includes(tag));
      const matchesCompany = !selectedCompany ||
        project.company === selectedCompany;
      return matchesTags && matchesCompany;
    });
  }, [selectedTags, selectedCompany]);

  const handleImageClick = (index) => {
    setVideoStates((prevStates) =>
      prevStates.map((state, i) => (i === index ? !state : state))
    );
  };

  return (
    <div id="projects">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pt-24 pb-12">
        <div style={{ width: "100%", alignItems: "center", justifyContent: "center", alignContent: "center", display: "flex" }}>
          <h2
            className={
              theme.state.darkMode
                ? "text-5xl font-bold px-4 md:px-0 text-center colored-bottom-line"
                : "text-5xl font-bold px-4 md:px-0 text-center text-white colored-bottom-line"
            }
          >
            Projects
          </h2>
        </div>
        <h4 className="mt-16 text-3xl font-semibold multicolortext" style={{ width: "max-content" }}>
          Debug.Log(builtProjects);
        </h4>

        {/* Filters Section */}
        <div className="mt-8 mb-6">
          <div className="flex flex-col gap-4">
            {/* Company Filter */}
            <div>
              <select
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5"
                value={selectedCompany}
                onChange={(e) => setSelectedCompany(e.target.value)}
              >
                <option value="">All Companies</option>
                {allCompanies.map(company => (
                  <option key={company} value={company}>{company}</option>
                ))}
              </select>
            </div>

            {/* Tags Filter */}
            <div className="flex flex-wrap gap-2">
              {allTags.map(tag => (
                <button
                  key={tag}
                  onClick={() => handleTagClick(tag)}
                  className={`px-3 py-1 rounded-full text-sm ${selectedTags.includes(tag)
                    ? 'bg-blue-500 text-white'
                    : 'bg-gray-200 text-gray-700'
                    }`}
                >
                  {tag}
                </button>
              ))}
            </div>
          </div>
        </div>

        {/* Projects Grid */}
        <div className="mt-8 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8">
          {filteredProjects.map((project, index) => (
            <Card
              key={index}
              title={project.name}
              description={project.description}
              imgUrl={project.imgUrl}
              videoUrl={project.videoUrl}
              link={project.link !== undefined ? project.link : ""}
              showVideo={videoStates[index]}
              handleImageClick={project.videoUrl !== undefined ? handleImageClick : {}}
              index={index}
              tags={project.tags}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Projects;
